// var fpxBank;
// var card;

// function fpxPayment(secretKey) {
//     ////console.log('Welcome to custom js');
//     // var form = document.getElementById('payment-form');

//     // form.addEventListener('submit', function (event) {
//     //     event.preventDefault();

//     var fpxButton = document.getElementById('fpx-button');
//     fpxButtonLoading = document.getElementById('fpx-button-loading');

//     fpxButton.disabled = true;
//     fpxButtonLoading.style.visibility = "visible"; 

//     var clientSecret = secretKey;
//     stripe.confirmFpxPayment(clientSecret, {
//         payment_method: {
//             fpx: fpxBank
//         },
//         // Return URL where the customer should be redirected after the authorization
//         return_url: `${window.location.href}`
//     }).then((result) => {
//         if (result.error) {
//             ////console.log('Fpx payment error')
//             // Inform the customer that there was an error.
//             var errorElement = document.getElementById('error-message');
//             errorElement.textContent = result.error.message;

//             status = 'Fpx payment error';

//             fpxButton.disabled = false;
//             fpxButtonLoading.style.visibility = "hidden"; 
//         } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
//             // Handle successful payment here
//             ////console.log('Fpx payment succeed')

//             fpxButton.disabled = false;
//             fpxButtonLoading.style.visibility = "hidden"; 
//         }
//     });
//     // });
// }

// function cardPayment(secretKey) {
//     ////console.log('Welcome to card js');

//     // var form = document.getElementById('card-payment-form');

//     // form.addEventListener('submit', function (event) {
//     //     event.preventDefault();
//     cardButton = document.getElementById('card-button');
//     cardButtonLoading = document.getElementById('card-button-loading');

//     cardButton.disabled = true;
//     cardButtonLoading.style.visibility = "visible"; 

//     var clientSecret = secretKey;
//     stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//             card: card
//         },
//         // Return URL where the customer should be redirected after the authorization
//         return_url: `${window.location.href}`
//     }).then((result) => {
//         if (result.error) {
//             ////console.log('Card payment error')
//             // Inform the customer that there was an error.
//             var errorElement = document.getElementById('error-message');
//             errorElement.textContent = result.error.message;

//             cardButton.disabled = false;
//             cardButtonLoading.style.visibility = "hidden"; 

//         } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
//             // Handle successful payment here
//             ////console.log('Card payment succeed' + JSON.stringify(result.paymentIntent))

//             var url = window.location.href;
//             if (url.indexOf('?') > -1) {
//                 url += '&payment_intent=' + result.paymentIntent.id
//             } else {
//                 url += '?payment_intent=' + result.paymentIntent.id
//             }
//             window.location.href = url;

//             cardButton.disabled = false;
//             cardButtonLoading.style.visibility = "hidden"; 
//         }
//     });
//     // });
// }

// function initStripe() {
//     ////console.log('initStripe');

//     var style = {
//         base: {
//             // Add your base input styles here. For example:
//             padding: '10px 12px',
//             color: '#32325d',
//             fontSize: '16px',
//         },
//     };

//     // Create an instance of the fpxBank Element.
//     fpxBank = elements.create('fpxBank',
//         {
//             style: style,
//             accountHolderType: 'individual',
//         }
//     );

//     // Add an instance of the fpxBank Element into the container with id `fpx-bank-element`.
//     fpxBank.mount('#fpx-bank-element');

//     // Create an instance of the fpxBank Element.
//     card = elements.create('card', {
//         iconStyle: 'solid',
//         style: {
//             base: {
//                 iconColor: '#c4f0ff',
//                 color: '#000',
//                 fontWeight: 500,
//                 fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
//                 fontSize: '16px',
//                 fontSmoothing: 'antialiased',

//                 ':-webkit-autofill': {
//                     color: '#fce883',
//                 },
//                 '::placeholder': {
//                     color: '#87BBFD',
//                 },
//             },
//             invalid: {
//                 iconColor: '#FFC7EE',
//                 color: '#FFC7EE',
//             },
//         },
//     });

//     // Add an instance of the fpxBank Element into the container with id `fpx-bank-element`.
//     card.mount('#card-element');
// }

function initGoogleMap() {
    ////console.log('Initializing Googlemap')
    
    var input = document.getElementById('searchTextField');
    var autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      document.getElementById('city2').value = place.name;
      document.getElementById('cityLat').value = place.geometry.location.lat();
      document.getElementById('cityLng').value = place.geometry.location.lng();
      //alert("This function is working!");
      //alert(place.name);
      // alert(place.address_components[0].long_name);

    });
  }